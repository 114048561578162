import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@feast-it/pesto';

import * as Styled from './styles';

const Option = ({
  variant,
  field,
  form,
  icon,
  value,
  label,
  showTick,
  onChange,
  ...props
}) => {
  const isMulti = Array.isArray(field.value);

  const checked = isMulti ? field.value.includes(value) : field.value === value;

  const handleChange = e => {
    const option = e.target.value;
    let newValue;

    if (isMulti) {
      if (checked) {
        newValue = field.value.filter(o => option !== o);
      } else {
        newValue = [...field.value, option];
      }
    } else {
      newValue = option;
    }

    form.setFieldValue(field.name, newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <Box as="label" {...props}>
      <Styled.Checkbox
        type={isMulti ? 'checkbox' : 'radio'}
        {...field}
        onChange={handleChange}
        checked={checked}
        value={value}
      />

      <Styled.Option checked={checked} variant={variant} title={label}>
        {icon && <Styled.OptionIcon name={icon} mr={3} variant={variant} />}

        <Styled.OptionValue>{label}</Styled.OptionValue>

        {showTick && checked && (
          <Styled.TickCircle checked={checked} variant={variant}>
            <Styled.Tick name="Tick" color="white" variant={variant} />
          </Styled.TickCircle>
        )}
      </Styled.Option>
    </Box>
  );
};

Option.defaultProps = {
  variant: 'default',
};

export const variants = [
  'default',
  'defaultLarge',
  'primary',
  'primaryInverted',
];

Option.propTypes = {
  variant: PropTypes.oneOf(variants).isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showTick: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Option;
