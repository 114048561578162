import styled from '@emotion/styled/macro';
import { Icon, inputReset, rem } from '@feast-it/pesto';
import { variant } from 'styled-system';

export const Checkbox = styled.input`
  ${inputReset}
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

const variants = (theme, checked) => ({
  default: {
    padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${
      theme.space[3]
    }`,
    borderRadius: theme.radii[3],
    fontSize: theme.fontSizes.body,
    color: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.greyLight,
    },
  },
  defaultLarge: {
    padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${
      theme.space[3]
    }`,
    borderRadius: theme.radii[3],
    fontSize: theme.fontSizes.large,
    color: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.greyLight,
    },
  },
  primary: {
    padding: `${rem(15)} ${theme.space[4]}`,
    border: `2px solid ${
      checked ? theme.colors.primary : theme.colors.greyLight
    }`,
    borderRadius: theme.radii[4],
    background: theme.colors.backDrop,
    fontSize: theme.fontSizes.large,
    fontFamily: theme.fonts.heading,
    color: checked ? theme.colors.primary : theme.colors.greyDark,
    '&:hover': {
      color: theme.colors.primary,
    },
  },
  primaryInverted: {
    padding: `${rem(15)} ${theme.space[4]}`,
    border: `2px solid ${checked ? theme.colors.white : theme.colors.primary}`,
    borderRadius: theme.radii[4],
    background: theme.colors.primary,
    fontSize: theme.fontSizes.large,
    fontFamily: theme.fonts.heading,
    color: theme.colors.white,
  },
});

export const Option = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  user-select: none;
  height: 100%;
  ${({ theme, checked }) => variant({ variants: variants(theme, checked) })}
`;

export const OptionValue = styled.div`
  flex: 1;
  line-height: ${({ theme }) => theme.lineHeights.body};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OptionIcon = styled(Icon)`
  ${variant({
    variants: {
      default: {
        width: rem(17),
        height: rem(17),
      },
      defaultLarge: {
        width: rem(20),
        height: rem(20),
      },
      primary: {
        width: rem(19),
        height: rem(19),
      },
      primaryInverted: {
        width: rem(19),
        height: rem(19),
      },
    },
  })}
`;

export const TickCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.space[3]};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green};
  opacity: ${({ checked }) => (checked ? '1' : '0')};
  ${variant({
    variants: {
      default: {
        width: rem(18),
        height: rem(18),
      },
      defaultLarge: {
        width: rem(20),
        height: rem(20),
      },
      primary: {
        width: rem(20),
        height: rem(20),
      },
      primaryInverted: {
        width: rem(20),
        height: rem(20),
      },
    },
  })}
`;

export const Tick = styled(Icon)`
  ${variant({
    variants: {
      default: {
        width: rem(8),
        height: rem(8),
      },
      defaultLarge: {
        width: rem(10),
        height: rem(10),
      },
      primary: {
        width: rem(10),
        height: rem(10),
      },
      primaryInverted: {
        width: rem(10),
        height: rem(10),
      },
    },
  })}
`;
